import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
    rounded-md
    bg-gray-100
  `,
  slots: {
    card: `
      p-6`,
  },
});
