'use client';

import { useMemo } from 'react';

import {
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Spacer,
  Stack,
} from '@backyard-ui/core';
import { useMediaQuery } from '@backyard-ui/hooks';
import { ChevronDown } from '@backyard-ui/icons';
import * as Accordion from '@radix-ui/react-accordion';
import { flow, groupBy, values } from 'lodash/fp';

import { type Category } from '../../Footer.data';

import styles from './Nav.styles';

import { List } from '.';

interface NavProps {
  items: Category[];
}

function Nav(props: NavProps) {
  const { items } = props;

  const classNames = styles({
    isBlackFriday:
      process.env.NEXT_PUBLIC_FEATURE_FLIP_BLACK_FRIDAY_ENABLED === 'true',
  });

  const isDesktop = useMediaQuery('lg');

  const groupColumns = useMemo(
    () => flow(groupBy('column'), values)(items),
    [items]
  );

  if (isDesktop) {
    return (
      <div className={classNames} data-testid="footer-nav">
        <SimpleGrid columns={4}>
          {groupColumns.map((column, index) => (
            <Stack direction="column" key={index}>
              {column.map((item: Category) => (
                <Flex direction="column" key={item.title}>
                  <Heading color="white" size="md" asChild>
                    <h4>{item.title}</h4>
                  </Heading>

                  <Spacer axis="vertical" size={3} />

                  <List items={item.items} />
                </Flex>
              ))}
            </Stack>
          ))}
        </SimpleGrid>
      </div>
    );
  }

  return (
    <Accordion.Root
      type="single"
      className="space-y-px"
      collapsible
      data-testid="footer-nav"
    >
      {items.map((group, index: number) => (
        <Accordion.Item
          key={index}
          value={`accordion-${index}`}
          className="rounded-md bg-blue-900 text-white"
        >
          <Accordion.Header>
            <Accordion.Trigger className="flex w-full items-center justify-between p-4 [&_svg]:data-[state=open]:rotate-180">
              <Heading color="white" size="sm" asChild>
                <h4>{group.title}</h4>
              </Heading>
              <Icon size="xs">
                <ChevronDown className="transition-transform duration-150 ease-in" />
              </Icon>
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="overflow-hidden data-[state=closed]:animate-accordion-closed data-[state=open]:animate-accordion-open">
            <List items={group.items} />
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
}

export default Nav;
