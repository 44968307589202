'use client';

import { datadogRum } from '@datadog/browser-rum';

const DATADOG_RUM_ENABLED =
  process.env.NEXT_PUBLIC_FEATURE_FLIP_DATADOG_RUM_ENABLED === 'true';
const DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
const DATADOG_APPLICATION_ID = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID;
const DATADOG_ENV_NAME = process.env.NEXT_PUBLIC_DATADOG_ENV_NAME;
const DATADOG_VERSION = process.env.NEXT_PUBLIC_DATADOG_VERSION;
const BASE_URL = process.env.NEXT_PUBLIC_ENDPOINT_LEROY;

const initRumDatadog = () => {
  if (!DATADOG_RUM_ENABLED) {
    return;
  }

  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'ameno',
    env: DATADOG_ENV_NAME,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    version: DATADOG_VERSION,
    allowedTracingUrls: [{ match: BASE_URL, propagatorTypes: ['datadog'] }],
  });
};

initRumDatadog();
