import { toast } from '@backyard-ui/core';

import {
  type Coordinates,
  type ZipCode,
} from '@/infra/services/user-context/model';
import { userContext } from '@/utils/user-context';

export const onUserRecontext = async (data: Coordinates | ZipCode) => {
  try {
    await userContext.recontext(data);

    const url = new URL(window.location.href);
    url.searchParams.delete('region');

    window.location.replace(url);
  } catch (error) {
    toast({
      title: 'Ops!',
      description:
        'Não conseguimos atualizar sua região. Por favor, tente novamente.',
      appearance: 'critical',
    });
  }
};

export const redirect = (url: string) => {
  window.location.href = url;
};
