'use client';
import { Container, Stack } from '@backyard-ui/core';

import { Contacts } from './components/Contacts';
import { Copyright } from './components/Copyright';
import { Engagement } from './components/Engagement';
import { Nav } from './components/Nav';
import { Social } from './components/Social';
import { categories } from './Footer.data';

function Footer() {
  return (
    <footer className="mt-10 w-full border-y-4 border-b-green-600 border-t-gray-200 py-6">
      <Container size="6xl">
        <Stack direction="column" spacing={4}>
          <Contacts />
          <Social />
          <Nav items={categories} />
          <Engagement />
          <Copyright />
        </Stack>
      </Container>
    </footer>
  );
}

export default Footer;
