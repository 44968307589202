import { tv } from 'tailwind-variants';

const styles = tv({
  base: 'rounded-md px-8 py-6',
  variants: {
    isBlackFriday: {
      true: 'bg-black',
      false: 'bg-blue-900',
    },
  },
});

export default styles;
