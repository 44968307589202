import {
  CACHE_CONTROL_KEY,
  CART_ID,
  CART_ITEMS_QUANTITY,
  LOCATION_SOURCE,
  REGION_NAME,
  REGION,
  ZIP_CODE,
  IS_ON_MOBILE_APP,
  TOTEM,
  AB_TESTING,
} from '@/utils/constants/cookies';
import {
  AB_TESTING_HEADER,
  MOBILE_APP_HEADER,
  REGION_HEADER,
  TOTEM_HEADER,
} from '@/utils/constants/headers';
import { cookies } from '@/utils/cookies';
import type { CookieOptions } from '@/utils/cookies/types';

type UserContextApiCookieName = keyof typeof cookiesApiMap;

export type UserContextCookiesObj = Record<UserContextApiCookieName, any>;

type CreateOptions = {
  locationSource: string;
};

// our API rest uses different names for the cookies, so we need to map them
const cookiesApiMap = {
  cacheControlKey: CACHE_CONTROL_KEY,
  cartId: CART_ID,
  cartItemsQuantity: CART_ITEMS_QUANTITY,
  locationSource: LOCATION_SOURCE,
  region: REGION,
  regionName: REGION_NAME,
  zipCode: ZIP_CODE,
};

const cookiesNameMap = {
  [CART_ID]: 'cartId',
  [CART_ITEMS_QUANTITY]: 'cartItemsQuantity',
  [LOCATION_SOURCE]: 'locationSource',
  [REGION]: 'region',
  [REGION_NAME]: 'regionName',
  [ZIP_CODE]: 'zipCode',
};

const arrCookiesNames = Object.values(cookiesApiMap);

const cookiesToHeadersMap = {
  [AB_TESTING]: AB_TESTING_HEADER,
  [IS_ON_MOBILE_APP]: MOBILE_APP_HEADER,
  [REGION]: REGION_HEADER,
  [TOTEM]: TOTEM_HEADER,
};

/*
 * Create an array of options to be used by cookies create method
 */
const createOptions = (options: CreateOptions) =>
  Object.entries(options).reduce((arrOptions, [apiCookieName, value]) => {
    const name = cookiesApiMap[apiCookieName as UserContextApiCookieName];

    if (name && value) {
      arrOptions.push(
        cookies.createOptions({
          name,
          value: String(value),
        })
      );
    }

    return arrOptions;
  }, [] as CookieOptions[]);

/*
 * Get all cookies from the server and return an object with the cookies values mapped to the API names
 */
const getAll = () => {
  const sessionCookies = cookies.client.getMany(arrCookiesNames);

  return sessionCookies.reduce(
    (acc, curr) => ({
      ...acc,
      // TODO: repensar essa lógica dos nomes
      [cookiesNameMap[curr.name]]: curr.value,
    }),
    {}
  ) as UserContextCookiesObj;
};

export default {
  names: arrCookiesNames,
  cookiesToHeadersMap,
  createOptions,
  getAll,
};
