import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
    fixed
    bottom-8
    right-8
    z-40
    flex
    h-10
    w-10
    items-center
    justify-center
    rounded-full
    bg-green-500
    p-3
    text-white
    transition-all
    duration-150
    ease-in
    hover:bg-green-600
    focus:outline-none
    focus:ring-4
    focus:ring-green-200
    md:h-14
    md:w-14
    md:p-4
    [&>svg]:h-full
    [&>svg]:w-full
  `,
});
