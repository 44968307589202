const LOYALTY_STATUS_LIST = {
  /**
   * Code to be used if User is not enrolled when sending LoyaltyProgram
   * information to our integrations (BUC/IDB/etc)
   */
  NOT_ENROLLED: 0,

  /**
   * User is active on Loyalty Program
   */
  ACTIVE: 1,

  /**
   * User opted-in on the Loyalty Program via the physical store and need to finish the enrollment
   */
  HAS_INTEREST: 2,

  /**
   * User has opted-out from the Loyalty Program
   */
  INACTIVE: 3,

  /**
   * User Pro Executor is active on Loyalty Program
   */
  PRO_EXECUTOR_ACTIVE: 4,

  /**
   * User Pro Executor has opted-out from the Loyalty Program
   */
  PRO_EXECUTOR_INACTIVE: 6,

  /**
   * User Pro EAD is active on Loyalty Program
   */
  PRO_EAD_ACTIVE: 7,

  /**
   * User Pro EAD opted-in on the Loyalty Program and waiting for documentation  validation
   */
  PRO_EAD_WAITING_DOCUMENTATION_VALIDATION: 8,

  /**
   * User Pro EAD has opted-out from the Loyalty Program
   */
  PRO_EAD_INACTIVE: 9,

  /**
   * User Pro EAD opted-in on the Loyalty Program and need to send documentation for validation
   */
  PRO_EAD_WITHOUT_DOCUMENTATION: 10,

  /**
   * User Pro EAD opted-in on the Loyalty Program but the documentation was denied
   */
  PRO_EAD_DOCUMENTATION_DENIED: 11,
};

export const LOYALTY_MEMBER_STATUS_LIST = [
  LOYALTY_STATUS_LIST.ACTIVE,
  LOYALTY_STATUS_LIST.PRO_EXECUTOR_ACTIVE,
  LOYALTY_STATUS_LIST.PRO_EAD_ACTIVE,
  LOYALTY_STATUS_LIST.PRO_EAD_WAITING_DOCUMENTATION_VALIDATION,
  LOYALTY_STATUS_LIST.PRO_EAD_WITHOUT_DOCUMENTATION,
  LOYALTY_STATUS_LIST.PRO_EAD_DOCUMENTATION_DENIED,
];
