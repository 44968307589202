import { isBrowser } from '@backyard-ui/utils';

import { pushDataObject } from './data-layer';

// Todo: Alinhar a possibilidade de remover o ga

type WindowWithGaType = Window &
  typeof globalThis & {
    ga: (...args: any[]) => void;
  };

const MOBILE_PREFIX = 'm_';

type TrackEventParams = {
  isMobile: boolean;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventValue?: any;
};

export const trackEvent = ({
  isMobile,
  eventCategory,
  eventAction,
  eventLabel,
  eventValue,
}: TrackEventParams) => {
  if (isMobile) {
    eventCategory = `${MOBILE_PREFIX}${eventCategory}`;
  }

  if (isBrowser && (window as WindowWithGaType).ga) {
    (window as WindowWithGaType).ga(
      'send',
      'event',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    );
  }

  // Todo: Se o ga for removido, esse evento pode ir para o `tracker.ts`
  pushDataObject({
    event: eventAction,
    eventCategory,
    eventLabel,
    eventValue,
  });
};
