import {
  SimpleGrid,
  Flex,
  Stack,
  Heading,
  Text,
  Spacer,
} from '@backyard-ui/core';

import { styles } from './Contacts.styles';

function Contacts() {
  const cardClassName = styles();

  return (
    <SimpleGrid
      gapX="4"
      columns={{
        initial: 1,
        sm: 2,
        md: 2,
        lg: 3,
      }}
      data-testid="footer-contacts"
    >
      <Flex direction="column" className={cardClassName}>
        <Stack direction="column" spacing={1}>
          <Heading size="md" asChild>
            <h3>Compre pelo WhatsApp</h3>
          </Heading>

          <Text color="neutral-700" size="md" weight="bold">
            Para todo o território nacional.
            <br />
            Horário de atendimento sujeito à região.
          </Text>
        </Stack>

        <Spacer axis="vertical" size={4} />

        <Text color="primary-700" size="3xl" weight="bold" asChild>
          <a href="tel:1140071380">(11) 4007-1380</a>
        </Text>
      </Flex>

      <Flex direction="column" className={cardClassName}>
        <Stack direction="column" spacing={1}>
          <Heading size="md" asChild>
            <h3>Compre pelo telefone</h3>
          </Heading>
          <Text color="neutral-700" size="md" weight="bold">
            Atendimento das 8h às 19h de segunda a sexta.
          </Text>
          <Text color="neutral-700" size="md" weight="bold">
            Atendimento das 8h às 14h aos sábados.
          </Text>
        </Stack>

        <Spacer axis="vertical" size={4} />

        <Text color="primary-700" size="3xl" weight="bold" asChild>
          <a href="tel:40071380">4007-1380</a>
        </Text>
        <Text color="neutral-700" size="md" weight="bold">
          Capitais
        </Text>

        <Spacer axis="vertical" size={4} />

        <Text color="primary-700" size="3xl" weight="bold" asChild>
          <a href="tel:08006021380">0800-602-1380</a>
        </Text>
        <Text color="neutral-700" size="md" weight="bold">
          Demais Regiões
        </Text>
      </Flex>

      <Flex direction="column" className={cardClassName}>
        <Heading size="md" asChild>
          <h3>Central de atendimento</h3>
        </Heading>
        <Text color="neutral-700" size="md" weight="bold">
          Das 08h às 19h de segunda a sábado, exceto feriados.
        </Text>

        <Spacer axis="vertical" size={4} />

        <Text color="primary-700" size="3xl" weight="bold" asChild>
          <a href="tel:40205376">4020-5376</a>
        </Text>
        <Text color="neutral-700" size="sm" weight="bold">
          Capitais (Telefone e WhatsApp)
        </Text>

        <Spacer axis="vertical" size={4} />

        <Text color="primary-700" size="3xl" weight="bold" asChild>
          <a href="tel:08000205376">0800-020-5376</a>
        </Text>
        <Text color="neutral-700" size="sm" weight="bold">
          Demais Regiões
        </Text>
      </Flex>
    </SimpleGrid>
  );
}

export default Contacts;
