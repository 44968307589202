import { Services } from '@/infra/services';
import {
  type Coordinates,
  type ZipCode,
} from '@/infra/services/user-context/model';
import { CART_ID, ZIP_CODE } from '@/utils/constants/cookies';
import { cookies } from '@/utils/cookies';

import { userContext } from './';

/*
 * Recontext user based on coordinates or zip code
 * and return an object with the cookies values
 */
export default async function recontext(params: Coordinates | ZipCode) {
  const postParams: Coordinates | ZipCode = {
    ...params,
    cartId: (await cookies.server.get(CART_ID)).value,
  };

  if (postParams.contextBy === 'zip_code') {
    postParams.zipCode = postParams.zipCode?.replace('-', '');
  }

  const { data } = await (postParams.contextBy === 'zip_code'
    ? Services.UserContext.postUserContextByZipCode(postParams)
    : Services.UserContext.postUserContextByCoordinates(postParams));

  const options = userContext.cookies.createOptions({
    ...data,
    locationSource: 'user',
  });

  await cookies.server.setMany(options);

  if (postParams.contextBy !== 'zip_code' || !postParams.zipCode) {
    const { domain } = cookies.createRemoveOptions(ZIP_CODE);

    cookies.server.remove(ZIP_CODE, domain);
  }

  return data;
}
