import { liteClient as search } from 'algoliasearch/lite';
import { type InstantSearchProps } from 'react-instantsearch';

export const APP_ID = process.env.NEXT_PUBLIC_SDK_ALGOLIA_APP_ID;
export const API_KEY = process.env.NEXT_PUBLIC_SDK_ALGOLIA_API_KEY;

export const searchClient = search(APP_ID, API_KEY);

export const IS_CATEGORY = false;

export const customSearchClient: InstantSearchProps['searchClient'] = {
  ...searchClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query && !IS_CATEGORY)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      });
    }

    return searchClient.search(requests);
  },
};

export const indexName = process.env.NEXT_PUBLIC_SDK_ALGOLIA_INDEX_NAME;
export const retailMediaIndexName =
  process.env.NEXT_PUBLIC_SDK_ALGOLIA_RETAIL_MEDIA_INDEX_NAME;
