import { type ModularContent } from '@/infra/services/modular-content/model';
import { slugify } from '@/utils/slugify';

import { pushDataObject } from './data-layer';
import { trackEvent } from './google';
import { productData, bannerPromotionData } from './promotion-data';
import { sendToApp } from './send-to-app';

type Banners = ModularContent.Banners['items'];
type Informatives = ModularContent.Informatives['items'];
type Product = ModularContent.Product;
type Slides = ModularContent.Slides['items'];

export const onSignupClick = ({ isDesktop }: { isDesktop: boolean }) => {
  const dataLayerObject = {
    event: 'signup_interactions',
    'event-category': 'interacoes_cadastro',
    'event-action': 'clique_em_cadastrar',
    'event-label': isDesktop ? 'modal_minha_conta' : 'menu_mobile_minha_conta',
  };

  pushDataObject(dataLayerObject);
};

type OnThumbnailClickParams = {
  id: string;
  itemTitle?: string;
  moduleTitle?: string;
  contentName?: string;
};

export const onThumbnailClick = ({
  id,
  itemTitle,
  moduleTitle,
  contentName,
}: OnThumbnailClickParams) => {
  const dataLayerObject = {
    event: 'carouselInteraction',
    element: moduleTitle,
    iconsCarouselName: `${contentName} - ${itemTitle}`,
    departmentId: id,
  };

  pushDataObject(dataLayerObject);
};

type OnBannerViewParams = {
  items: Slides | Banners | Informatives;
  region: string;
  moduleType: string;
};

export const onBannerView = ({
  items,
  moduleType,
  region,
}: OnBannerViewParams) => {
  const promotions = items.map((slide, index) =>
    bannerPromotionData({
      imageAlt: slide.image.alt,
      moduleType,
      title: 'title' in slide ? slide?.title : '',
      position: index + 1,
    })
  );

  const dataLayerObject = {
    event: 'promotionImpression',
    dimension14: region,
    ecommerce: {
      promoView: {
        promotions,
      },
    },
  };

  pushDataObject(dataLayerObject, true);
};

type OnBannerClickProps = {
  url: string;
  imageAlt: string;
  position: number;
  isMobile: boolean;
  moduleType: string;
  location: string;
  title: string;
};

export const onBannerClick = ({
  url,
  imageAlt,
  position,
  location,
  moduleType,
  isMobile,
  title,
}: OnBannerClickProps) => {
  const eventCategory = 'banner_TV';
  const eventAction = `clicou_banner - página ${location}`;
  const eventLabel = `${moduleType}_${url}`;
  const promotion = bannerPromotionData({
    imageAlt,
    moduleType,
    position,
    title,
  });

  pushDataObject({
    event: 'promotionClick',
    ecommerce: {
      promoClick: {
        promotions: [promotion],
      },
    },
  });

  trackEvent({ isMobile, eventCategory, eventAction, eventLabel });

  const appTrackData = {
    event: 'clickBanner',
    properties: {
      page_url: location,
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
    },
  };

  sendToApp({ type: 'tracker', ...appTrackData });
  sendToApp({ type: 'track', data: appTrackData });
};

type OnProductViewParams = {
  products: Product[];
  region: string;
  moduleType: string;
};

export const onProductView = ({
  products,
  region,
  moduleType,
}: OnProductViewParams) => {
  const formattedTitle = slugify(moduleType);

  const impressions = products.map((product, index) =>
    productData({ product, position: index + 1, moduleTitle: formattedTitle })
  );

  pushDataObject({
    event: 'productImpression',
    dimension14: region,
    ecommerce: {
      currencyCode: 'BRL',
      impressions,
    },
  });
};

type OnProductClickParams = {
  product: Product;
  position: number;
  moduleTitle: string;
};

export const onProductClick = ({
  product,
  position,
  moduleTitle,
}: OnProductClickParams) => {
  const list = slugify(moduleTitle, '_');
  const productClickData = productData({ product, position });

  pushDataObject({
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list: list },
        products: [productClickData],
      },
    },
  });
};

export const onError500PageAccess = () => {
  pushDataObject({
    event: 'error-pageview',
    virtualpage: '/erro-500',
  });
};

export const onError500PageActionClick = () => {
  pushDataObject({
    event: 'interaction',
    'event-category': 'Erro 500',
    'event-action': 'Clicou Voltar para a Home',
    'event-label': 'Clicou Voltar para a Home',
  });
};

export const onError404PageActionClick = () => {
  pushDataObject({
    event: 'interaction',
    'event-category': 'Erro 404',
    'event-action': 'Clicou Voltar para a Home',
    'event-label': 'Clicou Voltar para a Home',
  });
};

type SetAppRegionParams = {
  region: string;
  regionName: string;
};

export const setAppRegion = (params: SetAppRegionParams) => {
  sendToApp({
    type: 'setRegion',
    data: {
      name: params.regionName,
      slug: params.region,
    },
  });
};

export const onPageLoad = () => {
  sendToApp({
    type: 'onReady',
    data: {
      url: window.location.href,
      title: document.title,
    },
  });
};
