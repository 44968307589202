export interface Link {
  title: string;
  href: string;
  target?: '_blank';
}

export interface Category {
  title: string;
  column: string;
  items: Link[];
}

export const categories: Category[] = [
  {
    title: 'Departamentos',
    column: 'a',
    items: [
      {
        title: 'Banheiros',
        href: 'https://www.leroymerlin.com.br/banheiros',
      },
      {
        title: 'Cama, Mesa e Banho',
        href: 'https://www.leroymerlin.com.br/cama-mesa-e-banho',
      },
      {
        title: 'Climatização e Ventilação',
        href: 'https://www.leroymerlin.com.br/climatizacao-e-ventilacao',
      },
      {
        title: 'Cozinhas e Áreas de Serviço',
        href: 'https://www.leroymerlin.com.br/cozinhas-e-areas-de-servico',
      },
      {
        title: 'Decoração',
        href: 'https://www.leroymerlin.com.br/decoracao',
      },
      {
        title: 'Eletros',
        href: 'https://www.leroymerlin.com.br/eletros',
      },
      {
        title: 'Ferragens',
        href: 'https://www.leroymerlin.com.br/ferragens',
      },
      {
        title: 'Ferramentas',
        href: 'https://www.leroymerlin.com.br/ferramentas',
      },
      {
        title: 'Iluminação',
        href: 'https://www.leroymerlin.com.br/iluminacao',
      },
      {
        title: 'Jardim e Varanda',
        href: 'https://www.leroymerlin.com.br/jardim-e-lazer',
      },
      {
        title: 'Limpeza da Casa',
        href: 'https://www.leroymerlin.com.br/limpeza-e-manutencao',
      },
      {
        title: 'Madeiras',
        href: 'https://www.leroymerlin.com.br/marcenaria-e-madeiras',
      },
      {
        title: 'Materiais de Construção',
        href: 'https://www.leroymerlin.com.br/materiais-de-construcao',
      },
      {
        title: 'Materiais Elétricos',
        href: 'https://www.leroymerlin.com.br/materiais-eletricos',
      },
      {
        title: 'Materiais Hidráulicos',
        href: 'https://www.leroymerlin.com.br/materiais-hidraulicos',
      },
      {
        title: 'Móveis',
        href: 'https://www.leroymerlin.com.br/moveis',
      },
      {
        title: 'Organização da Casa',
        href: 'https://www.leroymerlin.com.br/organizacao',
      },
      {
        title: 'Pet Shop',
        href: 'https://www.leroymerlin.com.br/acessorios-para-pets',
      },
      {
        title: 'Pintura e Acessórios',
        href: 'https://www.leroymerlin.com.br/tintas-e-acessorios',
      },
      {
        title: 'Pisos e Revestimentos',
        href: 'https://www.leroymerlin.com.br/pisos-e-revestimentos',
      },
      {
        title: 'Portas, Janelas e Portões',
        href: 'https://www.leroymerlin.com.br/portas-janelas-e-portoes',
      },
      {
        title: 'Segurança e Comunicação',
        href: 'https://www.leroymerlin.com.br/seguranca-e-comunicacao',
      },
    ],
  },
  {
    title: 'Aprenda',
    column: 'b',
    items: [
      {
        href: 'https://www.leroymerlin.com.br/inspire-se',
        title: 'Inspire-se',
      },
      {
        href: 'https://www.leroymerlin.com.br/dicas',
        title: 'Dicas',
      },
      {
        href: 'https://www.leroymerlin.com.br/faca-voce-mesmo',
        title: 'Faça você mesmo',
      },
      {
        href: 'https://www.leroymerlin.com.br/cursos-e-eventos',
        title: 'Cursos e Eventos',
      },
      {
        href: 'https://www.leroymerlin.com.br/tendencias',
        title: 'Tendências',
      },
      {
        href: 'https://www.leroymerlin.com.br/sustentabilidade-e-desenvolvimento-responsavel',
        title: 'Sustentabilidade',
      },
      {
        href: 'https://www.leroymerlin.com.br/assistente',
        title: 'Calculadoras',
      },
      {
        href: 'https://www.leroymerlin.com.br/projetos/simuladores',
        title: 'Ferramentas de Projetos',
      },
    ],
  },
  {
    title: 'Serviços',
    column: 'b',
    items: [
      {
        href: 'https://www.leroymerlin.com.br/institucional/facilidades-nas-lojas',
        title: 'Facilidades nas lojas',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/venda-faturada',
        title: 'Venda Faturada Empresas',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/garantia-estendida-leroy-merlin',
        title: 'Garantia Estendida Leroy Merlin',
      },
    ],
  },
  {
    title: 'A Leroy Merlin',
    column: 'c',
    items: [
      {
        href: 'https://www.leroymerlin.com.br/institucional/sobre-a-leroy-merlin',
        title: 'A Leroy Merlin',
      },
      {
        href: 'https://www.leroymerlin.com.br/compliance',
        title: 'Compliance',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/politica-de-seguranca',
        title: 'Política de Segurança',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/historia',
        title: 'História',
      },
      {
        href: 'https://www.leroymerlin.com.br/afiliados',
        title: 'Programa de Afiliados',
      },
      {
        href: 'https://www.leroymerlin.com.br/sustentabilidade-e-desenvolvimento-responsavel',
        title: 'Sustentabilidade',
      },
      {
        href: 'https://carreiras.leroymerlin.com.br/',
        title: 'Trabalhe Conosco',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/ofereca-um-imovel',
        title: 'Ofereça um imóvel',
      },
      { href: 'https://www.leroymerlin.com', title: 'Site Internacional' },
      {
        href: 'https://www.leroymerlin.com.br/institucional/fornecedor-da-leroy-merlin',
        title: 'Seja um fornecedor',
      },
      { href: 'https://futurodoviver.com.br/', title: 'Inova Leroy Merlin' },
      {
        href: 'https://www.leroymerlin.com.br/institucional/comunidades',
        title: 'Comunidade Clientes',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/assessoria-de-imprensa',
        title: 'Sala de Imprensa',
      },
      {
        href: 'https://www.leroymerlin.com.br/fidelidade/como-funciona',
        title: 'Programa de Fidelidade',
      },
      {
        href: 'https://www.leroymerlin.com.br/sitemap-p.html',
        title: 'Mapa de buscas do site',
      },
      {
        href: 'https://carreiras.leroymerlin.com.br/pages/relatorio-de-transparencia-e-igualdade-salarial',
        title: 'Relatório de transparência e igualdade salarial',
        target: '_blank',
      },
    ],
  },
  {
    title: 'Marketplace',
    column: 'c',
    items: [
      {
        href: 'https://www.leroymerlin.com.br/modular/venda-com-a-gente',
        title: 'Venda com a gente',
      },
    ],
  },
  {
    title: 'Dúvidas',
    column: 'd',
    items: [
      {
        href: 'https://www.leroymerlin.com.br/institucional/politica-de-troca-e-devolucao',
        title: 'Política de troca',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/politica-de-pagamento',
        title: 'Política de pagamento',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/politica-de-entrega',
        title: 'Política de entrega',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/politica-de-privacidade',
        title: 'Política de privacidade',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/politica-de-cookies',
        title: 'Política de Cookies',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/termos-e-condicoes-de-uso-do-site',
        title: 'Termos e condições',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/regulamento-programa-leroy-merlin-com-voce',
        title: 'Regulamento Leroy Merlin com Você',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/avaliacao',
        title: 'Avaliação de Produtos',
      },
      {
        href: 'https://www.leroymerlin.com.br/modular/recall-churrasqueira',
        title: 'Recall',
      },
    ],
  },
  {
    title: 'Atendimento',
    column: 'd',
    items: [
      {
        href: 'https://www.leroymerlin.com.br/institucional/perguntas-frequentes-do-ecommerce',
        title: 'FAQ de eCommerce',
      },
      {
        href: 'https://www.leroymerlin.com.br/institucional/perguntas-frequentes-da-loja-fisica',
        title: 'FAQ de Loja Física',
      },
      {
        href: 'https://www.leroymerlin.com.br/fale-conosco',
        title: 'Fale conosco',
      },
    ],
  },
];
