import { type Response as LoyaltyProfileType } from '@/infra/services/user/get-loyalty-profile';
import { type Response as UserResourcesType } from '@/infra/services/user/get-user-resources';
import { LOYALTY_MEMBER_STATUS_LIST } from '@/utils/constants/loyalty-status';

import { type GlobalState } from '../global';

interface BuildUserArgs {
  userResources?: UserResourcesType;
  session: GlobalState['user']['session'];
  loyaltyProfile?: LoyaltyProfileType['data'];
}

/**
 *
 * This function is responsible for mapping data from API
 * to the interface global state defines.
 * TODO: Some rules are defined here but we might extract to a class in domain layer
 */
export const buildUser = ({
  userResources,
  session,
  loyaltyProfile,
}: BuildUserArgs): GlobalState['user'] => {
  if (!userResources) {
    return { session };
  }

  const isAssistedSale = userResources?.context === 'assisted-sale';
  const isLoyaltyEligible = !isAssistedSale && !userResources?.isLegalUser;

  const isLoyaltyMember = LOYALTY_MEMBER_STATUS_LIST.some(
    (status) => status === loyaltyProfile?.status
  );

  return {
    resources: userResources,
    session,
    controls: {
      isLoggedIn: Boolean(userResources?.email),
      isAssistedSale,
      isLoyaltyEligible,
      isLoyaltyMember,
      isLegalUser: userResources?.isLegalUser || false,
    },
    loyaltyProfile: loyaltyProfile,
  };
};
