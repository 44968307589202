'use client';

import { usePathname, useParams } from 'next/navigation';

import { useGlobalStore } from '@/presenters/store/global';

function useAnalyticsTags() {
  const { device, isLoggedIn, isOnMobileApp, region } = useGlobalStore(
    (store) => ({
      region: store.user.session.region,
      isLoggedIn: store.user.controls?.isLoggedIn,
      isOnMobileApp: store.server.isOnMobileApp,
      device: store.server.device,
    })
  );

  const pathname = usePathname();
  const { slug } = useParams();

  const analyticsTags: string[] = [];

  if (!region) {
    return analyticsTags;
  }

  if (pathname === '/search') {
    analyticsTags.push('#searchresults');
  }

  if (pathname === '/entry-key' && slug) {
    analyticsTags.push('#categorypage');
  }

  analyticsTags.push(`#${region}`);
  analyticsTags.push(isLoggedIn ? '#loginOn' : '#loginOff');
  analyticsTags.push(`#${isOnMobileApp ? 'app' : device}`);

  return analyticsTags;
}

export default useAnalyticsTags;
