import { isBrowser } from '@backyard-ui/utils';

interface DataLayerType {
  push: (...args: any[]) => void;
}

type WindowWithDataLayer = Window &
  typeof globalThis & {
    dataLayer: DataLayerType;
  };

export const pushDataObject = (dataObject: any, needReset = false) => {
  if (!isBrowser) {
    return;
  }

  const dataLayer = (window as WindowWithDataLayer).dataLayer || [];

  if (needReset) {
    dataLayer.push(() => {
      (this as any).reset();
    });
  }

  dataLayer.push(dataObject);
};
