import { type GlobalState } from '@/presenters/store/global';

export function userAdapter(user: GlobalState['user']) {
  return {
    email: user.resources?.email ?? '',
    name: user.resources?.name ?? '',
    picture: user.resources?.picture ?? '',

    loyaltyPoints: user.loyaltyProfile?.points.actual ?? 0,

    isLegalUser: Boolean(user.resources?.isLegalUser),
    isAuthenticated: Boolean(user.controls?.isLoggedIn),
    isAssistedSale: Boolean(user.controls?.isAssistedSale),
    isLoyaltyMember: Boolean(user.controls?.isLoyaltyMember),
    canJoinLoyalty: Boolean(user.controls?.isLoyaltyEligible),
  };
}

export function locationContextAdapter(
  session: GlobalState['user']['session']
) {
  return {
    region: session.region,
    regionName: session.regionName,
    locationSource: session.locationSource || 'user',
  };
}
