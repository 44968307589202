import Image from 'next/image';

import { SimpleGrid, Stack, Heading, Text } from '@backyard-ui/core';

function Engagement() {
  return (
    <SimpleGrid
      columns={{
        initial: 1,
        md: 2,
        lg: 3,
      }}
      gapX="4"
      className="rounded-md bg-gray-100"
      data-testid="footer-engagement"
    >
      <Stack align="center" spacing={2} className="rounded-sm p-6">
        <Image
          src="/images/footer/footer-sustentabilidade.png"
          alt="Sustentabilidade - Construir e Sustentar"
          width={54}
          height={54}
        />
        <div>
          <Heading color="primary-700" size="lg" asChild>
            <h3>Sustentabilidade</h3>
          </Heading>
          <Text size="sm">Construir e Sustentar</Text>
        </div>
      </Stack>
    </SimpleGrid>
  );
}

export default Engagement;
