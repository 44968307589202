import { useEffect, useState } from 'react';

import { ErrorReporter } from '@/infra/monitoring/error-reporter';
import { Services } from '@/infra/services';
import { type Response as LoyaltyProfileType } from '@/infra/services/user/get-loyalty-profile';
import { type Response as UserResourcesType } from '@/infra/services/user/get-user-resources';
import { type GlobalState } from '@/presenters/store/global';
import { CART_ID_HEADER } from '@/utils/constants/headers';
import { userContext } from '@/utils/user-context';

/**
 *
 * This hook is intended to be used once as it feches data from APIs.
 * The data is fetched in client side since we're caching the page in cloudflare.
 * To access the user's data, use the global state.
 *
 */
export const useGetUserData = () => {
  const [userResources, setUserResources] = useState<UserResourcesType>();
  const [loyaltyProfile, setLoyaltyProfile] =
    useState<LoyaltyProfileType['data']>();
  const [userSession, setUserSession] =
    useState<GlobalState['user']['session']>();

  useEffect(() => {
    const getUser = async () => {
      try {
        const userContextCookies = await userContext.cookies.getAll();

        setUserSession(userContextCookies);

        const userResourcesOptions = userContextCookies.cartId
          ? {
              headers: {
                [CART_ID_HEADER]: userContextCookies.cartId,
              },
            }
          : {};

        const resources = await Services.User.getUserResources(
          userResourcesOptions
        );

        setUserResources(resources);

        if (Boolean(resources.email)) {
          const loyalty = await Services.User.getLoyaltyProfile();
          setLoyaltyProfile(loyalty.data);
        }
      } catch (error) {
        ErrorReporter.report(error);
      }
    };

    getUser();
  }, []);

  return {
    userSession,
    userResources,
    loyaltyProfile,
  };
};
