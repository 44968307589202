import { createContext } from '@backyard-ui/utils';
import { createStore, type StoreApi } from 'zustand';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { type Response as LoyaltyProfileType } from '@/infra/services/user/get-loyalty-profile';
import { type UserResources } from '@/infra/services/user/model';

export interface GlobalState {
  /**
   * Settings from server-side, eg:
   * user-agent, ip address, etc
   */
  server: {
    /**
     * Get device type via user agent
     */
    device: 'desktop' | 'mobile' | (string & Object);

    /**
     * Detect mobile via query string or user-agent, eg:
     * http://localhost:3000/?isOnMobileApp=true
     * Remove header and footer on APP
     */
    isOnMobileApp?: boolean;
  };

  /**
   * User data/settings
   */
  // TODO: definir essa tipagem na camada de domínio, sem utilizar o backpack como referência
  user: {
    resources?: Omit<UserResources, 'loyaltyProgram' | 'isEmployee'>;
    controls?: {
      isLoggedIn: boolean;
      isAssistedSale: boolean;
      isLoyaltyEligible: boolean;
      isLoyaltyMember: boolean;
      isLegalUser: boolean;
    };
    session: {
      region: string;
      cartId?: string;
      regionName?: string;
      locationSource?: 'user' | 'cloudflare';
      zipCode?: string;
    };

    loyaltyProfile?: LoyaltyProfileType['data'];
  };
}

export interface GlobalActions extends GlobalState {
  /**
   *
   */
  updateUser: (value: Partial<GlobalState['user']>) => void;
  updateCartItems: (value: UserResources['cartItems']) => void;
}

const createGlobalStore = (initialState: GlobalState) =>
  createStore<GlobalActions>()((set) => ({
    ...initialState,
    updateUser: (value) =>
      set((state) => ({
        ...state,
        user: {
          // TODO: rever essa lógica para poder remontar todo usuário
          // a partir apenas dos novos dados
          ...state.user,
          ...value,
        },
      })),
    updateCartItems: (value) =>
      set((state) => {
        if (state.user.resources) {
          return {
            ...state,
            user: {
              ...state.user,
              resources: {
                ...state.user.resources,
                cartItems: value,
              },
            },
          };
        }

        return state;
      }),
  }));

const { Provider: GlobalProvider, useContext: useGlobalContext } =
  createContext<StoreApi<GlobalState>>({
    name: '@store/Global',
  });

const useGlobalStore = <T>(
  selector: (state: GlobalActions) => T,
  equalityFn: (a: any, b: any) => boolean = shallow
) => {
  const context = useGlobalContext();

  return useStoreWithEqualityFn(
    context as StoreApi<GlobalActions>,
    selector,
    equalityFn
  );
};

export { GlobalProvider, createGlobalStore, useGlobalStore };
