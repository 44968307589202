'use client';

import { useEffect, useState } from 'react';

import { Alert, CloseButton, Container } from '@backyard-ui/core';

import { useGlobalStore } from '@/presenters/store/global';
import { EMPLOYEE_NOTIFICATION } from '@/utils/constants/cookies';
import { cookies } from '@/utils/cookies';

function EmployeeNotification() {
  const [showNotification, setShowNotification] = useState(
    !cookies.client.has(EMPLOYEE_NOTIFICATION)
  );

  const companyId = useGlobalStore((state) => state.user.resources?.companyId);
  const name = useGlobalStore((state) => state.user.resources?.name);

  useEffect(() => {
    if (Boolean(companyId)) {
      const cookieOptions = cookies.createOptions({
        name: EMPLOYEE_NOTIFICATION,
        value: 'true',
      });

      return cookies.client.set(cookieOptions);
    }
  }, [companyId]);

  if (!companyId || !showNotification) {
    return null;
  }

  return (
    <div className="relative">
      <div className="absolute top-0 z-40 w-full">
        <Alert.Root UNSAFE_className="rounded-none">
          <Container size="6xl">
            <div className="flex justify-between">
              <Alert.Content>
                <Alert.Title UNSAFE_className="mb-2 text-xl">
                  Olá, {name}!
                </Alert.Title>
                <Alert.Description>
                  Vimos que trabalha com a gente, por isso você tem direito a
                  descontos em nosso site! Esses descontos funcionam da seguinte
                  forma: 20% em produtos MDH, 10% nos outros produtos. O
                  desconto de colaborador não é aplicado em produtos que já
                  estão em oferta ou que não sejam vendidos pela Leroy Merlin.
                </Alert.Description>
              </Alert.Content>
              <CloseButton
                size="xs"
                aria-label="Fechar mensagem"
                onClick={() => setShowNotification(false)}
              />
            </div>
          </Container>
        </Alert.Root>
      </div>
    </div>
  );
}

export default EmployeeNotification;
