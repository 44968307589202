import { Text } from '@backyard-ui/core';

import { type Link } from '../../Footer.data';

interface ListProps {
  items: Link[];
}

function List(props: ListProps) {
  const { items } = props;

  return (
    <ul className="space-y-2 pb-4 pl-4 lg:p-0">
      {items.map((item) => (
        <li key={item.title}>
          <Text color="white" size="md" asChild>
            <a href={item.href} title={item.title} target={item.target}>
              {item.title}
            </a>
          </Text>
        </li>
      ))}
    </ul>
  );
}

export default List;
