import {
  type NavigationRepository,
  type LocationRepository,
} from '@leroy-merlin-br/backpack-header';

import { Services } from '@/infra/services';

export const locationRepository: LocationRepository = {
  getByCityName: async (cityName) => {
    const locations = await Services.Location.getLocationsByCityName({
      cityName,
    });
    return locations;
  },
  getByZipcode: async (zipcode) => {
    const location = await Services.Location.getLocationByZipcode({
      zipcode,
    });
    return location;
  },
};

export const navigationRepository: NavigationRepository = {
  getChildrenById: async (subcategoryId: string) => {
    const { data } = await Services.Categories.getSubcategoryChildren({
      subcategoryId,
    });

    return data.results;
  },
};
