import { isBrowser } from '@backyard-ui/utils';

interface ReactNativeWebViewType {
  postMessage: (...args: any[]) => void;
}

type WindowWithRNWVType = Window &
  typeof globalThis & {
    ReactNativeWebView: ReactNativeWebViewType;
  };

export const sendToApp = (params: any) => {
  if (!isBrowser) return;

  const windowWithRNWV = window as WindowWithRNWVType;

  if (
    !windowWithRNWV.ReactNativeWebView ||
    !windowWithRNWV.ReactNativeWebView.postMessage
  ) {
    return;
  }

  const postMessageContent = JSON.stringify(params);
  windowWithRNWV.ReactNativeWebView.postMessage(postMessageContent);
};
